// Summary And Greeting Section

import emoji from "react-easy-emoji";

const illustration = {
  animated: false
};

const greeting = {
  username: "Aswath Shakthi",
  title: "Aswath Shakthi",
  subTitle:
    "• Data Scientist & NLP Researcher • Azure Data Science Associate • Exploring areas in Neuro Informatics, Neuromorphic & Cognitive Computing ",
  resumeLink:
    "https://www.icloud.com/iclouddrive/0BecvPTi1qgjn5Rn3Vhu65EpQ#website",
  displayGreeting: true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/ash-sha",
  linkedin: "https://www.linkedin.com/in/aswathshakthi/",
  gmail: "reachme@aswathshakthi.co.uk",
  //instagram: "https://www.instagram.com/aswathshakthi/",
  //facebook: "https://www.facebook.com/aswathshakthi/",
  stackoverflow: "https://stackoverflow.com/users/8435172/ash",
  //twitter:"https://twitter.com/aswathshakthi",
  researchgate: "https://www.researchgate.net/profile/Aswath_Shakthi",
  medium: "https://medium.com/@aswathshakthi",
  kaggle: "https://www.kaggle.com/aswathshakthi",
  display: true
};

// Skills Section

const skillsSection = {
  title: "Skills",
  subTitle: " ",
  skills: [
      emoji(
      " ○ Experienced in Data & Language modeling, Machine learning tools, Research methodologies,  " +
      "Statistical techniques for producing solution to Problems.",
    ),
      emoji(
      " ○ Worked on Text Analysis Problems in NLP and Deep Learning",
    ),
      emoji(
      " ○ Acquainted with multiple structures and unstructured datasets including Medical, Financial and Retail Data",
    ),
      emoji(
      " ○ Familiar with K-NN, Decision Tree, Regression, Classification, Clustering, SVM, Naive bayes, Random Forest, PCA, Conformal Prediction, CNN, RNN, LSTM",
    ),
      emoji(
      " ○ Ran data experiments, train predictive models, managed, optimized, and deployed ML models into production\n",
    ),
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "Python",
      url_img: require("./assets/images/python.png")
    },
    {
      skillName: "R",
      url_img: require("./assets/images/r.png")
    },
      {
      skillName: "Postgre/SQL",
      url_img: require("./assets/images/sql.png")
    },
      {
      skillName: "PySpark",
      url_img: require("./assets/images/pyspark.svg")
    },
      {
      skillName: "NumPy",
      url_img: require("./assets/images/numpy.svg")
    },
      {
      skillName: "Pandas",
      url_img: require("./assets/images/pandas.png")
    },
      {
      skillName: "SciKit-Learn",
      url_img: require("./assets/images/scik.png")
    },
      {
      skillName: "Jupyter Notebook",
      url_img: require("./assets/images/jupyter.png")
    },
      {
      skillName: "MatplotLib",
      url_img: require("./assets/images/matplotlib.png")
    },
      {
      skillName: "TensorFlow",
      url_img: require("./assets/images/tensor.png")
    },
      {
      skillName: "Keras",
      url_img: require("./assets/images/keras.png")
    },
      {
      skillName: "Tableau",
      url_img: require("./assets/images/tableau.svg")
    },
      {
      skillName: "Azure ML",
      url_img: require("./assets/images/azure.png")
    },

    {
      skillName: "Zoho CRM",
      url_img: require("./assets/images/zoho.png")
    },
      {
      skillName: "Hadoop",
      url_img: require("./assets/images/hadoop.svg")
    },
      {
      skillName: "Databricks",
      url_img: require("./assets/images/databricks.jpg")
    },


  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "Royal Holloway, University of London",
      logo: require("./assets/images/rhul1.png"),
      subHeader: "MSc, Data Science and Analytics",
      duration: "September 2019 - December 2020",
      desc: "Pass with Merit",
      descBullets: [
        "Modules : Data Analysis, Machine Learning, Deep Learning, Natural Language Processing, Databases, large Scale Data Processing & Storage, Object Oriented Programming, Ethics in AI.",
        "Personal Advisor: Prof. Volodya Vovk",
        "Master's Thesis in Natural Language Processing: Measuring Sentence Similarity in Bio Medical Domain using Deep Learning Models",
        "Project Advisor: Prof. Yang Gao"]
    },
    {
      schoolName: "King's College, London",
      logo: require("./assets/images/kcl-logo.svg"),
      subHeader: "International Relations",
      duration: "June 2016 - June 2016",
      desc:
        "",
      descBullets: [" Have assessed the utility or validity of different theories of International Relations for thinking about specific issues in world politics",
        "Have developed team, organisational, communication, and other academic, practical and interpersonal skills, such as can undertaking complex and non-routine performance tasks requiring self-reflection",
        "Faculty : Dr. Diana Bozhilova"]
    },
    {
      schoolName: "SASTRA Deemed to be University, Tanjore",
      logo: require("./assets/images/sastra.png"),
      subHeader: "B.Tech in Information and Communication Technology",
      duration: "July 2013 - May 2017",
      desc:
        "",
      descBullets: ["Modules : Software Engineering, Cloud Computing, Data Mining, Cyber law, Information Hiding, Operating System and more",
        "Languages: C, C++, Java, R, Python",
        "Bachelor's Thesis in Machine Learning: Classification of Ratings and reviews of online opinion using Machine learning Algorithms.",
        "Advisor: Prof. Brindha.G.R.",]
    },
    {
      schoolName: "SRV Matriculation Hr Sec School, Trichy",
      logo: require("./assets/images/srv.png"),
      subHeader: "Higher Secondary Certificate",
      duration: "July 2011- May 2013",
      desc:
      "",

      descBullets: ["Maths, Physics, Chemistry, Computer Science "]
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: false, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Python&R", //Insert stack or technology you have experience in
      progressPercentage: "75%" //Insert relative proficiency in percentage
    },
    {
      Stack: "ML Tools",
      progressPercentage: "80%"
    },
    {
      Stack: "Programming",
      progressPercentage: "60%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
      {
      role: "Junior Data Scientist",
      company: "Palm AI",
      companylogo: require("./assets/images/palmai.png"),
      date: "Oct 2021 - Present",
      desc:
        " London, United Kingdom",
      descBullets: [
          "Design a NLP model, process and analyse data to cluster companies for growth comparison by providing relevant, optimised intelligence to aid decision making.", "Deliver an engaging, insightful Platform that enables UK tech scaleups fulfil accelerate their growth." ,
          "Develop a MVP , Launch and iterate for a scalable product"

      ]
    },
      {
      role: "Data Analytics Virtual Internship",
      company: "The Forage",
      companylogo: require("./assets/images/forage.jpeg"),
      date: "Mar 2021 - May 2021",
      desc:
        " London, United Kingdom",
      descBullets: [
        "Clients: General Electric, ANZ Bank, Quantium Analytics, KPMG.",
        "Used Data Engineering to combine full-flight engine, part manufacturing, airport location data to create a data lake in Tableau for Analysts to use.",
        "Analyzed data to create a run chart and KPI tables based on simulated aviation data. Segmented the supermarket datasets and drew unique insights, including visualization of the transaction volume and assessing the effect of any outliers. Presented it as Dashboards using Tableau.",
        "Explored and visualized correlations between customer attributes, built a regression and a decision-tree prediction model based on the findings.",
        "Created a Spark Application and developed simple IaaS and PaaS solutions in public cloud environments.",
        "Data wrangled bank transactions dataset and identified customer purchasing behaviors to generate insights and provide commercial recommendations.",
        "Analysis/Statistical testing to help identify benchmark that allows testing the impact on customer sales."
      ]
    },

    {
      role: "Machine Learning Research Intern",
      company: "SASTRA University",
      companylogo: require("./assets/images/sastra.png"),
      date: "Aug 2017 - Oct 2017",
      desc:
        "Tanjore, India",
      descBullets:[
        "Researched on Text analysis and Natural Language Processing using SVM, Naive Bayes and Max Entropy Algorithms on Medical domain.",
        "Achieved Classification Accuracy of Microarray Data For Cancer cells 10% better than traditional methods. Introduced new feature selection methods and classifications.",

          ]
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  githubConvertedToken: process.env.REACT_APP_GITHUB_TOKEN,
  githubUserName: "ash-sha", // Change to your github username to view your profile in Contact Section.
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: false // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Projects",
  subtitle: "Some of the personal projects i have worked on",
  projects: [
    {
      image: require("./assets/images/p1.png"),
      projectName: "Measuring Semantic Similarity for Gene Ontology ",
      projectDesc: " Improved Semantic similarity between sentences in Biomedical Texts of 1068 Sentence pairs containing 10K+ tokens, using Neural Models like CNN, RNN, LSTM. Using BioSentVec embedding Evaluated the performance of each model using Python. Achieved accuracy of 98.87% better than traditional methods. ",
      footerLink: [
        {
          name: "GitHub",
          url: "https://github.com/ash-sha/Semantic-Textual-Similarity-NLP"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/p2.jpg"),
      projectName: "Large Scale Email Analysis",
      projectDesc: " Email Analysis on millions of structured dataset as a Hadoop Sequence File comprising a collection of binary objects each of which representing a single Email message using Pyspark.",
      footerLink: [
        // {
        //  // name: "Visit Website",
        //   //url: "http://nextu.se/"
        // }
      ]
    },

  {
      image: require("./assets/images/p4.png"),
      projectName: "Predicting Cancer using Enhanced ML Techniques ",
      projectDesc: "Cancer prediction using Naive Bayes and Maximum Entropy algorithms in R programming. Applied enhanced Classification technique, Point-wise Mutual information and Pruning to obtain efficient Model accuracy of 98.66% on Colon, Leukaemia & Lymphoma cancer of 7000+ gene expressions.",
      footerLink: [
        {
          name: "GitHub",
          url: "https://github.com/ash-sha/Enhanced-NB-Classification"
        }
        //  you can add extra buttons here.
      ]
    },
{
      image: require("./assets/images/p3.png"),
      projectName: "Enhanced Text Classification of Product Reviews",
      projectDesc: "  Modelled using SVM, Maximum Entropy and various weightings using Yelp Review Datasets of 3000+ samples in R programming. Due to its limitations in weightSMART function, altered the source code of R package and expanded the new document weights and produced a better accuracy of 79% & 92%.",
      footerLink: [
        {
          name: "GitHub",
          url: "https://github.com/ash-sha/Frequency-Weighting"
        }
        //  you can add extra buttons here.
      ]
    },
{
      image: require("./assets/images/p6.webp"),
      projectName: "Opinion Analysis of Online Reviews.",
      projectDesc: "  Developed a new Polarity method for finding sentiments in online feedbacks. Trained with Naive Bayes and Maximum Entropy Classifier using Python, wxPython for Front-End. This method improved the efficiency to 77% better than the traditional methods. Datasets: Twitter Datasets of 10K tweets for training and Amazon products dataset of 1000 samples for training and validation." +
          "s and estimating the accuracy of polarity (Python).",
      footerLink: [
        {
          name: "GitHub",
          url: "https://github.com/ash-sha/Opinion-Analysis"
        }
        //  you can add extra buttons here.
      ]
    },
],
  display: true // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Publications",
  subtitle:
    "",

  blogs: [
    {
      url:
        "http://www.iaeme.com/MasterAdmin/Journal_uploads/ijmet/VOLUME_8_ISSUE_10/IJMET_08_10_038.pdf",
      title: "Enhanced Classification Through Improved Feature Selection Technique",
      description:
        "Aswath Shakthi, Brindha.G.R., Bharathi.N. International Journal of Mechanical Engineering and Technology 8(10), 2017, pp. 342–351."
    },
    {
      url: "https://ieeexplore.ieee.org/document/8474746",
      title: "New Feature Selection Process to Enhance Naïve Bayes Classification",
      description:
        "Oruganti Tejaswini, K.P Aswath Shakthi, Khadavilli Ramya Geethika, G.R. Brindha. Second International Conference on Electronics, Communication and Aerospace Technology (ICECA),March 29-31, 2018, Coimbatore, pp.98-101."
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Courses & Certifications "),
  subtitle:
    "Courses, Certifications that I have done !",

  achievementsCards: [
      {
      title: "Azure Data Science Associate",
      subtitle: "Planning and creating a suitable working environment for data science workloads on Azure. Run data experiments, train predictive models. In addition, manage, optimize, and deploy ML models into production. Describe principles of differential privacy",
      image: require("./assets/images/azureds.png"),
      footerLink: [

      ]
    },
      {
      title: "IBM Data Science Professional Certificate",
      subtitle: "Completed 9 Courses on Data science and Machine Learning using SQL&Python. Learnt to Visualize Various data using seaborn and Matplotlib.",
      image: require("./assets/images/c11.png"),
      footerLink: [
        {name: "Certification", url: "https://coursera.org/share/d9a48c2ad453ed11eb7a94223c26d207"},
        {
          name: "Badge",
          url: "https://www.youracclaim.com/badges/efcaf58b-496d-4134-ac05-caf3b8a03a09/"
        }
      ]
    },

      {
      title: "Natural Language Processing ",
      subtitle: "Skills Learnt: Chatterbot, \n" +
          "Tensorflow, \n" +
          "Deep Learning, \n" +
          "Natural Language Processing, NLTK.",
      image: require("./assets/images/c14.jpg"),
      footerLink: [
        // {name: "Certification", url: "https://coursera.org/share/4f26731f4d0c96a6aa5e2259bf887cb7"},
        // {
        //   name: "Badge",
        //   url: "https://www.youracclaim.com/badges/47332f7f-6509-464e-918d-104db80abe8b"
        // }
      ]
    },
      {
      title: "TensorFlow Developer Professional Certificate",
      subtitle: "Skills Learnt: Computer Vision\n" +
          "CNN, \n" +
          "ML, \n" +
          "NLP, \n" +
          "Tensorflow, \n" +
          "Inductive Transfer, \n" +
          "Augmentation, \n" +
          "Dropouts, \n" +
          "Tokenization, \n" +
          "RNNs, \n" +
          "Time Series.",
      image: require("./assets/images/c16.png"),
      footerLink: [
        // {name: "Certification", url: "https://coursera.org/share/4f26731f4d0c96a6aa5e2259bf887cb7"},
        // {
        //   name: "Badge",
        //   url: "https://www.youracclaim.com/badges/47332f7f-6509-464e-918d-104db80abe8b"
        // }
      ]
    }

  ],
  display: true // Set false to hide this section, defaults to true
};


// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    ""
  ),

  talks: [
    {
      title: "",
      subtitle: "",
      slides_url: "",
      event_url: ""
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "Some of my Favourite Podcasts",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://podcasts.apple.com/us/podcast/the-minimalists-podcast/id1069757084"
  ],
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact"),
  subtitle:
    "",
  number1: "+44-07774914812",
  number2: "+91-75987-42404",
  email_address: "reachme@aswathshakthi.co.uk"
};

// Twitter Section

const twitterDetails = {
  userName: "twitter", //Replace "twitter" with your twitter username without @
  display: false // Set true to display this section, defaults to false
};

export {
  illustration,
  greeting,
  socialMediaLinks,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  blogSection,
  achievementSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails
};
